import React, { useEffect } from 'react'
import MyAsideBarActive from '../Components/asideBarActive'
import { useState} from "react";
import TablePanneRow from '../Components/Table/TablePanneRow';
import MyAsideBar from "../Components/asideBar";
import MyNavBar from "../Components/navBar";
import { useAuthContext } from '../hooks/useAuthContext';
import CostumSelectCentre from '../Components/Form/CostumSelectCentre';
import moment from "moment/moment";

export const PanneListLivrees = () => {
    const [act, setAct] = useState(false);
    const [search, setSearch] = useState("");
    const [centredepot, setcentredepot] = useState("All");
    const [datedepot, setdatedepot] = useState();
    const [dateDebutdepot, setdateDebutdepot] = useState(null);
    const [dateFindepot, setdateFindepot] = useState(null);
    const [ProduitenPanne, setProduitenPanne] = useState([]);
    const { user } = useAuthContext();

    const handleCentreInputChange = (newValue) => {
      setcentredepot(newValue);
    };
    const handleDateDebutdepotInputChange = (event) => {
      const formattedDate = moment(event.target.value).format("YYYY-MM-DD");
      setdateDebutdepot(formattedDate);
      if (dateFindepot && moment(event.target.value).isAfter(dateFindepot)) {
        setdateFindepot("Invalid date");
      }
    };
    const handleDateFindepotChange = (event) => {
      const formattedDate = moment(event.target.value).format("YYYY-MM-DD");
      setdateFindepot(formattedDate);
      if (dateDebutdepot && moment(event.target.value).isBefore(dateDebutdepot)) {
        setdateFindepot("Invalid date");
      }
    };
    
    useEffect(() => {
      const fetchPannesData = async () => {
        try {
          const queryParams = new URLSearchParams({
            Role: user?.Role,
            CentreDepot: user?.Centre,
            UserID: user?.id,
          });
    
          const response = await fetch(process.env.REACT_APP_URL_BASE+`/Pannes/Delivred/?${queryParams}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`,
            },
          });
    
          if (response.ok) {
            const data = await response.json();
            setProduitenPanne(data.Pannes);
          } else {
            console.error("Error receiving Panne data:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching Panne data:", error);
        }
      };
    
      fetchPannesData();
    }, [user?.Centre, user?.Role, user?.id, user?.token]);
  return (
    <>
    <MyNavBar  act={act} setAct={setAct} />
      <MyAsideBar />
    <div className="Patients-Details">
      <div className="patient-table">
        <MyAsideBarActive act={act} setAct={setAct}></MyAsideBarActive>
        <div className="patient-table-container">
          <div className="patient-table-header">
            <div className="table-header-item">
              <label>Date de debut :</label>
              <input
                type="Date"
                className="class-search"
                placeholder="Date de debut"
                onChange={handleDateDebutdepotInputChange}
                value={dateDebutdepot}
              />
            </div>
            <div className="table-header-item">
              <label>Date de fin :</label>
              <input
                type="Date"
                className="class-search"
                placeholder="Date de fin"
                onChange={handleDateFindepotChange}
                value={dateFindepot}
              />
            </div>
            <div className="table-header-item">
            <CostumSelectCentre label='Centre:' onChange={handleCentreInputChange}/>
            </div>
            <div className="table-header-item">
              <label>Recherche</label>
              <input
              type="search"
              className="class-search"
              placeholder="Search.."
              onChange={(e) => setSearch(e.target.value)}
            />
            </div>
            
          </div>
          <div className="table-patients">
            <table>
              <tr className="table-patients-header">
                <td className="table-patients-header-id">Id</td>
                <td className="table-patients-header-nom">Nom Complet</td>
                <td className="table-patients-header-phone">Telephone</td>
                <td className="table-patients-header-nom">Produit</td>
                <td className="table-patients-header-nom">Date</td>
                <td className="table-patients-header-nom">Centre</td>
                <td className="table-patients-header-id">Statut garantie</td>
                <td className="table-patients-header-button"></td>
              </tr>
              {ProduitenPanne?.filter((item) => {
                const searchMatch =
                  search.toLowerCase() === "" ||
                  item.id.toString().includes(search.toLowerCase()) ||
                  item.Nom.toLowerCase().includes(search.toLowerCase()) ||
                  item.Prenom.toLowerCase().includes(search.toLowerCase()) ||
                  item.DateDepot.toLowerCase().includes(search.toLowerCase()) ||
                  item.Progres.toString().includes(search.toLowerCase()) ||
                  item.CentreDepot.toLowerCase().includes(search.toLowerCase()) ||
                  item.ReferanceProduit.toLowerCase().includes(search.toLowerCase()) ||
                  item.TypePanne.toLowerCase().includes(search.toLowerCase());

                const centreMatch =
                  centredepot === "All" || item.CentreDepot.toLowerCase().includes(centredepot.toLowerCase());

                const dateMatch =
                  ((!dateDebutdepot || dateDebutdepot == "Invalid date") || moment(item.DateDepot, "YYYY-MM-DD").isSameOrAfter(dateDebutdepot)) &&
                  ((!dateFindepot || dateFindepot == "Invalid date") || moment(item.DateDepot, "YYYY-MM-DD").isSameOrBefore(dateFindepot));

                return searchMatch && centreMatch && dateMatch;
              }).map((Panne) => (
                <TablePanneRow Panne={Panne} />
              ))}

            </table>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
