import React, { useEffect } from 'react'
import MyAsideBarActive from '../Components/asideBarActive'
import {IoArrowBackCircleSharp, IoArrowForwardCircle} from  "react-icons/io5"
import { FaCircle } from "react-icons/fa";
import { useState} from "react";
import TablePanneRow from '../Components/Table/TablePanneRow';
import MyAsideBar from "../Components/asideBar";
import MyNavBar from "../Components/navBar";
import { useAuthContext } from '../hooks/useAuthContext';
import CostumSelectCentre from '../Components/Form/CostumSelectCentre';
import ProgressionSelect from '../Components/Form/ProgressionSelect';
import './Style/PanneList.css'
import { CircularProgress } from '@mui/material';
import moment from 'moment';

export const PanneList = () => {
    const [act, setAct] = useState(false);
    const [search, setSearch] = useState("");
    const [centredepot, setcentredepot] = useState("All");
    const [progres, setprogres] = useState("All");
    const [datedepot, setdatedepot] = useState(null);
    const [dateDebutdepot, setdateDebutdepot] = useState(null);
    const [dateFindepot, setdateFindepot] = useState(null);
    const [ProduitenPanne, setProduitenPanne] = useState([]);
    const { user } = useAuthContext();
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 15;
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const [totalPages, settotalPages] = useState(0);
    const handleCentreInputChange = (newValue) => {
      setcentredepot(newValue);
      setCookie("centre", newValue, 7);

    };
    const handleProgresInputChange = (newValue) => {
      setprogres(newValue);
      setCookie("progression", newValue, 7);

    };
    const handleDateInputChange = (event) => {
      setdatedepot(event.target.value);
      setCookie("date", event.target.value, 7);
    };
    const handleSearchInputChange = (event) => {
      setSearch(event.target.value)
      setCookie("search", event.target.value, 7);
    };
    const handleDateDebutdepotInputChange = (event) => {
      const formattedDate = moment(event.target.value).format("YYYY-MM-DD");
      setdateDebutdepot(formattedDate);
      if (dateFindepot && moment(event.target.value).isAfter(dateFindepot)) {
        setdateFindepot("Invalid date");
      }
    };
    const handleDateFindepotChange = (event) => {
      const formattedDate = moment(event.target.value).format("YYYY-MM-DD");
      setdateFindepot(formattedDate);
      if (dateDebutdepot && moment(event.target.value).isBefore(dateDebutdepot)) {
        setdateFindepot("Invalid date");
      }
    };

    useEffect(() => {
      const fetchPannesData = async () => {
        try {
          const queryParams = new URLSearchParams({
            Role: user?.Role,
            CentreDepot: user?.Centre,
            UserID: user?.id,
          });
    
          const response = await fetch(process.env.REACT_APP_URL_BASE+`/Pannes/?${queryParams}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`,
            },
          });
    
          if (response.ok) {
            const data = await response.json();
            setProduitenPanne(data.Pannes);
          } else {
            console.error("Error receiving Panne data:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching Panne data:", error);
        }
      };
    
      fetchPannesData();
    }, [user?.Centre, user?.Role, user?.id, user?.token, search, datedepot, progres, centredepot]);
    // Filter data based on search criteria
    const filteredPanneData = ProduitenPanne?.filter(item => {
        if(
          (
            search.toLowerCase() === "" ||
            item.id.toString().includes(search.toLowerCase()) ||
            item.Nom.toLowerCase().includes(search.toLowerCase()) ||
            item.Prenom.toLowerCase().includes(search.toLowerCase()) ||
            item.Progres.toString().includes(search.toLowerCase()) ||
            item.CentreDepot.toLowerCase().includes(search.toLowerCase()) ||
            item.ReferanceProduit.toLowerCase().includes(search.toLowerCase()) ||
            item.TypePanne.toLowerCase().includes(search.toLowerCase()) ||
            formatDate(item.DateDepot).toLowerCase().includes(search.toLowerCase())
          )
          &&
          (
            centredepot === "All" ||
            item.CentreDepot.toLowerCase().includes(centredepot.toLowerCase())
          )
          &&
          (
            ((!dateDebutdepot || dateDebutdepot == "Invalid date") || moment(item.DateDepot, "YYYY-MM-DD").isSameOrAfter(dateDebutdepot)) &&
            ((!dateFindepot || dateFindepot == "Invalid date") || moment(item.DateDepot, "YYYY-MM-DD").isSameOrBefore(dateFindepot))
          )
          &&
          (
            progres === "All" ||
            (item.Progres.toString().includes(progres.toString()) && item.Etat === null) ||
            (progres.toString() === '6' ? item.Etat !== null : null)
          )
        ){
          return item;
        }
        return null;
    });
    const slicedData = (filteredPanneData?.length < 1 && (search === "" && datedepot === null && progres === "All" && centredepot === "All")) ? ProduitenPanne : filteredPanneData;
    const handleNextPage = () => {
      if(ProduitenPanne !== null){
        if (currentPage < Math.ceil(ProduitenPanne.length / rowsPerPage)) {
          setCurrentPage(currentPage + 1);
        }
      }
      
    };
    const handlePrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
    // Reset currentPage to 1 when search criteria change or data is filtered
    useEffect(() => {
      settotalPages(Math.ceil(slicedData?.length / rowsPerPage));
      setCurrentPage(1); 
    }, [search, datedepot, progres, centredepot, slicedData?.length, rowsPerPage]);
    useEffect(() => {
      const savedDate = getCookie("date");
      const savedCentre = getCookie("centre");
      const savedProgression = getCookie("progression");
      const savedSearch = getCookie("search");

      if (savedDate) setdatedepot(savedDate);
      if (savedCentre) setcentredepot(savedCentre);
      if (savedProgression) setprogres(savedProgression);
      if (savedSearch) setSearch(savedSearch);
  }, []);
    return (
    <>
    <MyNavBar  act={act} setAct={setAct} />
      <MyAsideBar />
    <div className="Patients-Details">
      <div className="patient-table">
        <MyAsideBarActive act={act} setAct={setAct}></MyAsideBarActive>
        <div className="patient-table-container">
          <div className="patient-table-header">
          <div className="table-header-item">
              <label>Date de debut :</label>
              <input
                type="Date"
                className="class-search"
                placeholder="Date de debut"
                onChange={handleDateDebutdepotInputChange}
                value={dateDebutdepot}
              />
            </div>
            <div className="table-header-item">
              <label>Date de fin :</label>
              <input
                type="Date"
                className="class-search"
                placeholder="Date de fin"
                onChange={handleDateFindepotChange}
                value={dateFindepot}
              />
            </div>
            <div className="table-header-item">
            <CostumSelectCentre label='Centre:' value={centredepot} onChange={handleCentreInputChange}/>
            </div>
            <div className="table-header-item">
              <ProgressionSelect label='Progression:' value={progres}  onChange={handleProgresInputChange}/>
            </div>
            <div className="table-header-item">
              <label>Recherche</label>
              <input
              type="search"
              className="class-search"
              placeholder="Search.."
              value={search}
              onChange={(e) => handleSearchInputChange(e)}
            />
            </div>
            
          </div>
          {ProduitenPanne && ProduitenPanne !== undefined ?
          <div className="table-patients">
            <table>
              <tr className="table-patients-header">
              <td className="table-patients-header-id">id</td>
                <td className="table-patients-header-nom">Nom Complet</td>
                <td className="table-patients-header-phone">Telephone</td>
                <td className="table-patients-header-nom">Produit</td>
                <td className="table-patients-header-nom">Date</td>
                <td className="table-patients-header-nom">Centre</td>
                <td className="table-patients-header-id">Statut garantie</td>
                <td className="table-patients-header-id">Suspension</td>
                <td className="table-patients-header-button">
                  <div className="pagination-buttons">
                      <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        {(currentPage > 1) ?
                          <IoArrowBackCircleSharp className='next-back-row-table'/>
                          :
                          <FaCircle className='null-next-back-row-table'/>
                        }
                      </button>
                    <h1>{currentPage}</h1>
                      <button onClick={ProduitenPanne ? handleNextPage : null} disabled={ProduitenPanne ? currentPage === totalPages : null}>
                        {currentPage < totalPages ?
                          <IoArrowForwardCircle className='next-back-row-table'/>
                          :
                          <FaCircle className='null-next-back-row-table'/>
                        }
                      </button>
                  </div>
                </td>
                <td className="table-patients-header-button"></td>
              </tr>
              {slicedData.length < 1 ? (
                <td colSpan={7}>
                  <div style={{ margin: '20px 0', textAlign: 'center' }}>
                    <h1>Aucune panne disponible</h1>
                  </div>                
                </td>
                ) : (
                  slicedData.slice(startIndex, endIndex).map(Panne => (
                    <TablePanneRow key={Panne.id} Panne={Panne} />
                  )
                )
              )
            }
            </table>
          </div>
          :
          <div className="CircularProgress-container">
            <CircularProgress className='CircularProgress' />
          </div>
          }
        </div>
      </div>
    </div>
    </>
  )
}
function formatDate(dateString) {
  const timeZone = 'Africa/Algiers'; // Algeria's time zone
  const date = moment.tz(dateString, timeZone); // Parse the date string with the specified time zone
  const monthNames = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  const month = monthNames[date.month()];
  const day = date.date();
  const year = date.year();
  const hours = date.hours();
  const minutes = date.minutes();

  const formattedDate = `${month} ${day}, ${year} at ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return formattedDate;
}
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
